import React, { useState } from 'react';
import axios from 'axios';
import { Alert, Modal, Button, Form } from 'react-bootstrap';

const LoadDetails = ({ shipment, userData, shipmentObjUpdateEvent, BilledMilesEdit }) => {
  const showBilledMilesEdit = BilledMilesEdit === "show";
  const [showModal, setShowModal] = useState(false);
  const [newMiles, setNewMiles] = useState('');
  const [responseMessage, setResponseMessage] = useState(null);
  const [isOperationSuccessful, setIsOperationSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setNewMiles('');
    setResponseMessage(null);
    setIsOperationSuccessful(false);
  };

  const handleInputChange = (event) => {
    setNewMiles(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `https://api.shippersedge.com/billing/${shipment.id}/updateMiles`,
        { miles: newMiles, name: userData.name, email: userData.email },
        {
          headers: {
            'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW',
            'Content-Type': 'application/json',
          },
        }
      );
      // only update billed cost if new cost is returned
      if (response.data.newCost) {
        const targetCharge = shipment.chargesList?.find(charge => charge.type === 'billedCost');
        if (targetCharge) {
          const event = {
            preventDefault: () => { },
            target: {
              name: 'charges.billedCost',
            },
          };
          shipmentObjUpdateEvent(event, response.data.newCost);
        }
      }

      setResponseMessage("Updated");
      setIsOperationSuccessful(true);
    } catch (error) {
      if (error.response) {
        setResponseMessage(error.response.data.message);
      } else {
        setResponseMessage('An error occurred');
      }
      setIsOperationSuccessful(false);
    } finally {
      setIsLoading(false);
    }
  };

  // console.log('LoadDetails', userData)
  if (shipment) {
    return (
      <div>
        <span className="cardBodyRow1">Load Details:</span><br />
        Equipment: {shipment.loadDetails.equipment ? shipment.loadDetails.equipment.charAt(0).toUpperCase() + shipment.loadDetails.equipment.slice(1) : 'Van'}
        {(shipment.loadDetails.temperature && shipment.loadDetails.temperature !== "") ? <> {shipment.loadDetails.temperature}°f</> : null}
        <br />
        <>Weight: {shipment.loadDetails.totalWeight}</>
        {userData.clientCode !== "dlfcanada" ?
          <> lbs</>
          : <> kg</>
        }

        {(shipment.customerId !== "kwiktrip") ?
          <>

            <br />
            Distance: {shipment.loadDetails.miles} Miles <small>(Source: Google)</small>
          </> : null
        }


        <br />
        {/* This condition needs to come from the API */}
        {showBilledMilesEdit && <a href="#" onClick={handleOpenModal}>Alter Billable Miles</a>}

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Alter Billable Miles</Modal.Title>
          </Modal.Header>
          <Modal.Body>k
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formMiles">
                <Form.Label>New Miles</Form.Label>
                <Form.Control type="number" value={newMiles} onChange={handleInputChange} />
              </Form.Group>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Update'}
              </Button>
            </Form>
          </Modal.Body>
          {responseMessage && (
            <Alert variant={isOperationSuccessful ? 'success' : 'danger'} style={{ marginTop: '10px', marginBottom: 0 }}>
              {responseMessage}
            </Alert>
          )}
        </Modal>
      </div>
    )
  } else {
    return null
  }
}

export default LoadDetails
